/* eslint-env browser */

import EmblaCarousel from 'embla-carousel';

import initModal from './init-modal.js';
import {
    trackCVEntryBtnClick,
    trackEntryBtnClick,
    trackSalaryEntryClick,
} from './services/tracking-service.js';

const ENTRY_HIDDEN_KEY = 'job-profile-entry-hidden-at';

function main() {
    const renderRoot = document.querySelector(
        'job-profile-entry-podlet-isolated',
    )?.shadowRoot;

    if (!renderRoot) {
        return;
    }

    // ONBOARDING ENTRY
    const entryHidden = Number(localStorage.getItem(ENTRY_HIDDEN_KEY)) || false;

    const panel = renderRoot.querySelector(
        '.job-profile-onboarding--entry-exit-panel',
    );

    if (panel && !entryHidden) {
        panel.classList.remove('hidden');
        panel.classList.add('flex');

        renderRoot
            .querySelector('#job-profile-onboarding--cross-button__entry')
            ?.addEventListener('click', () => {
                localStorage.setItem(ENTRY_HIDDEN_KEY, Date.now().toString());
                panel?.remove();
            });
    }

    // EXIT
    renderRoot
        .querySelector('#job-profile-onboarding--cross-button__exit')
        ?.addEventListener('click', () => {
            renderRoot
                .querySelector('.job-profile-onboarding--entry-exit-panel')
                ?.remove();
        });

    // ONBOARDING ENTRY LINK
    const profileEntryLink = renderRoot.querySelectorAll(
        '.job-profile-onboarding--entry-link',
    );

    Array.from(profileEntryLink).forEach((el) =>
        el.addEventListener('click', () => {
            trackEntryBtnClick(
                el.href,
                el.dataset.objectPosition,
                '',
                el.dataset.cardEntryEnabled ? 'CardLink' : 'Banner',
            );
        }),
    );

    // CV ENTRY LINK
    renderRoot
        .querySelector('.cv-builder-entry-link')
        ?.addEventListener('click', () => {
            trackCVEntryBtnClick();
        });

    // ACTIVE PROFILE ENTRY
    const activeProfileEntryLink = renderRoot.querySelectorAll(
        '.job-profile--nudge-link',
    );
    Array.from(activeProfileEntryLink).forEach((el) =>
        el.addEventListener('click', () => {
            const { objectName, objectPosition } = el.dataset;
            trackEntryBtnClick(
                el.href,
                objectPosition,
                objectName,
                'Banner',
                'Job profile nudge',
            );
        }),
    );

    // SALRY COMPARISON
    const salaryComparisonEntryLinks = renderRoot.querySelectorAll(
        '.job-salary-web-entry-link',
    );
    Array.from(salaryComparisonEntryLinks).forEach((el) => {
        el.addEventListener('click', () => {
            const { objectElementType, objectPosition, targetType } =
                el.dataset;
            trackSalaryEntryClick(
                objectElementType,
                objectPosition,
                targetType,
            );
        });
    });

    // LANDING PAGE MODAL GENERAL
    /**
     * The modal needs at least 1 second delay in order for scroll-doctor to work properly.
     * This is because the MFP renders ads that contain some sort of script that overrides the
     * styling that scroll-doctor sets on document.body. The delay will (in most cases where user
     * has somewhat fast internet) make scroll-doctor set it's styling _after_ the ads are rendered,
     * preventing the disappearal of scroll lock styling
     */
    setTimeout(() => {
        initModal(renderRoot);
    }, 1000);

    // CAROUSEL
    const emblaNode = renderRoot.querySelector(
        '#job-frontpage-entry-card-links-carousel',
    );

    if (emblaNode) {
        EmblaCarousel(emblaNode, {
            dragFree: true,
        });
    }
}

main();
