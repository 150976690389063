import pulse from '@finn-no/pulse-sdk';

export function trackModal(id, type, intent, objectName) {
    pulse.trackEvent({
        type,
        intent,
        name: 'Job profile',
        object: {
            id,
            type: 'UIElement',
            elementType: 'Popup',
            position: 'MarketFrontpage',
            name: objectName,
        },
        vertical: {
            name: 'job',
        },
    });
}

export function trackModalEntryBtnClick() {
    pulse.trackEvent({
        type: 'Click',
        intent: 'Open',
        name: 'Job profile',
        object: {
            id: 'job_profile_landing_create_profile_link_click',
            type: 'UIElement',
            elementType: 'Popup',
            position: 'MarketFrontpage',
        },
        target: {
            id: '/job/profile/onboarding/work-situation',
            type: 'UserProfilePage',
            pageType: 'my_account',
        },
        vertical: {
            name: 'job',
        },
    });
}

/**
 * @param {string} targetId - URL of the anchor's target
 * @param {('Listing'|'MarketFrontpage')} objectPosition - Where the podlet is mounted (search result or market frontpage)
 * @param {string} name - Name of the event, defaults to 'Job profile'
 * @param {string?} objectName - Optional object name to differentiate between events
 * @param {string} elementType - Name of element type.
 */
export function trackEntryBtnClick(
    targetId,
    objectPosition,
    objectName,
    elementType,
    name = 'Job profile',
) {
    pulse.trackEvent({
        type: 'Click',
        intent: 'Open',
        name,
        object: {
            id: 'job_profile_entry_click',
            type: 'UIElement',
            elementType,
            position: objectPosition,
            name: objectName,
        },
        target: {
            id: targetId,
            type: 'UserProfilePage',
            pageType: 'my_account',
        },
        vertical: {
            name: 'job',
        },
    });
}

export function trackCVEntryBtnClick() {
    // JOB_ev87
    pulse.trackEvent({
        type: 'Click',
        intent: 'Open',
        name: 'CV builder',
        object: {
            id: 'cv_builder_entry_click',
            type: 'UIElement',
            elementType: 'CardLink',
            position: 'MarketFrontpage',
        },
        vertical: {
            name: 'job',
            subVertical: 'cv',
        },
    });
}

export function trackSalaryEntryClick(
    objectElementType,
    objectPosition,
    targetType,
) {
    // JOB_ev90, JOB_ev110, JOB_ev111
    pulse.trackEvent({
        type: 'Click',
        intent: 'Open',
        name: 'Salary comparison',
        object: {
            id: 'salary_comparison_entry_click',
            type: 'UIElement',
            elementType: objectElementType || '',
            name: 'Compare your salary',
            position: objectPosition || '',
        },
        target: {
            type: targetType || '',
            pageType: 'Salary comparison',
        },
        vertical: {
            name: 'job',
            subVertical: 'salary_comparison',
        },
    });
}
