/* eslint-env browser */

import { setup, teardown } from 'scroll-doctor';

import {
    setModalNotNowInLocalStorage,
    shouldShowModal,
} from './services/local-storage-service.js';
import {
    trackModal,
    trackModalEntryBtnClick,
} from './services/tracking-service.js';

export default function initModal(renderRoot) {
    const modal = renderRoot.querySelector('#job-profile-frontpage-modal');

    // LANDING PAGE MODAL GENERAL
    const showModal = shouldShowModal();

    const removeModal = () => {
        teardown();
        setModalNotNowInLocalStorage();
        modal.remove();
    };

    if (modal && showModal) {
        setup(renderRoot.querySelector('#job-profile-landing-modal--content'));
        modal.classList.remove('hidden');
        modal.classList.add('flex');

        // Track view for modal
        trackModal(
            'job_profile_landing_modal_show',
            'View',
            'Show',
            'Show modal',
        );

        // LANDING PAGE MODAL NOT_NOW LINK
        const landingPageNotNowLink = renderRoot.querySelector(
            '#job-profile-landing--not_now-link',
        );

        landingPageNotNowLink?.addEventListener('click', () => {
            trackModal(
                'job_profile_landing_modal_not_now_click',
                'Click',
                'Dismiss',
                'Not now',
            );
            removeModal();
        });

        // LANDING PAGE MODAL EXIT
        const modalExitBtn = renderRoot.querySelector(
            '#job-profile-landing-modal--crossoff-icon',
        );

        modalExitBtn?.addEventListener('click', () => {
            trackModal(
                'job_profile_landing_modal_close_click',
                'Click',
                'Dismiss',
                'Close',
            );
            removeModal();
        });

        // LANDING PAGE MODAL CREATE PROFILE CLICK
        const createProfileBtn = renderRoot.querySelector(
            '#job-profile-landing--create_profile-link',
        );

        createProfileBtn?.addEventListener('click', () => {
            trackModalEntryBtnClick();
        });
    }
}
