/* eslint-env browser */

const KEY = 'landing-page-modal-close';
const TTL = 60 * 60 * 24 * 7 * 1000; // 7 days in ms

export function setModalNotNowInLocalStorage() {
    window.localStorage.setItem(KEY, Date.now().toString());
}

export function shouldShowModal() {
    const timestampStr = window.localStorage.getItem(KEY);

    if (!timestampStr) {
        return true;
    }

    const currentTimestamp = Date.now();
    const shouldShow = currentTimestamp > Number(timestampStr) + TTL;

    // Cleanup localstorage entry if the TTL has expired
    if (shouldShow) {
        window.localStorage.removeItem(KEY);
    }

    return shouldShow;
}
